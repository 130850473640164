var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__body"},[_c('div',{staticClass:"boxes"},[_vm._l((_vm.items),function(item,index){return [(
						_vm.checkModule({
							moduleName: item.moduleName,
							pageName: item.pageName,
						})
					)?_c('router-link',{key:index + '_route',attrs:{"to":{ name: item.to }}},[_c('div',{staticClass:"text",style:({ position: 'relative' })},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('div',{staticClass:"text__title",domProps:{"textContent":_vm._s(item.title)}}),_c('div',{staticClass:"text__description",domProps:{"textContent":_vm._s(item.description)}}),(
								item.to == 'ContractNotification' &&
								_vm.agreementNotificationsAmount
							)?_c('v-btn',{staticClass:"amountNotify",attrs:{"height":"26","width":"26","color":"red","fab":"","dark":"","small":""}},[_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.agreementNotificationsAmount)+" ")])]):_vm._e()],1)]):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }